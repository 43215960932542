<template>
  <v-app>
    <v-card
      class="bgss rounded-0 d-flex justify-center"
      flat
      width="100%"
      height="100%"
    >
    
      <div class="mx-auto box transition-fast-in-fast-out px-2 px-sm-8" v-if="shop_info">
        <p class="titles1 text-center mb-14">{{ $t("商店已经准备就绪") }}</p>
        <v-row class="my-0" align="center">
          <div class="d-flex">
            <div>
              <v-img class="bg" src="@/assets/images/login/bg.png"></v-img>
            </div>
          </div>
          <div  class="ml-sm-12 py-5">
            <v-card color="#EDD4FF" flat class="pa-4 mb-8 text-body-1">
              <v-icon color="primary" size="32">mdi-store-outline</v-icon>
              {{ $t("店铺名称") }}：<span class="primary--text">{{shop_info.shop_name}}</span>
            </v-card>
            <v-card color="#EDD4FF" flat class="pa-4 mb-8 text-body-1">
              <v-icon color="primary" size="32">mdi-email-outline</v-icon>
              {{ $t("电子邮箱") }}：<span class="primary--text">{{user_info.email}}</span>
            </v-card>
            <a :href="'http://'+shop_info.shop_web_domain" target="_blank">
              <v-card color="#EDD4FF" flat class="pa-4 text-body-1">
                <v-icon color="primary" size="32"> mdi-web</v-icon>
                {{ $t("店铺地址") }}：<span class="primary--text">{{shop_info.shop_web_domain}}</span>
              </v-card>
            </a>
            <div style="max-width: 400px">
              <p class="mt-5 mb-0 text-caption">
                *{{ $t("请确认基本的商店信息，之后您可以在管理中更改订阅") }}  <a href="https://taocarts.shop/manger_admin/setting" target="_blank">{{$t('去修改')}}</a>
              </p>
            </div>
            <div class="d-flex justify-space-between mt-4">
              <a :href="'http://'+shop_info.shop_web_domain" target="_blank">
            <v-btn
              class="primary-background px-8 rounded-pill white--text"
            
              >{{ $t("进入我的店铺") }}</v-btn
            >
          </a>
              <!-- <a href="https://taocarts.shop/manger_admin" target="_blank">
            <v-btn
              class="primary-background px-8 rounded-pill white--text"
            
              >{{ $t("管理后台") }}</v-btn
            >
          </a> -->
              <a  :href="`${$store.state.locationOrigin}/manger_admin`" target="_blank">
            <v-btn
              class="primary-background px-8 rounded-pill white--text"
            
              >{{ $t("管理后台") }}</v-btn
            >
          </a>
            </div>
              
          </div>
        </v-row>
        <p class="titles1 text-center mb-8 margin90">
          {{ $t("查看您的商店业务概述") }}
        </p>
        <v-card flat class="pa-4 pa-md-15">
          <div class="d-flex align-center mb-6 align-start" v-if="shop != ''">
            <v-card width="16" height="16" color="primary" flat></v-card>
            <span class="pl-2 text-body-1"
              >{{ $t("多达") }} {{ shop.good.goods_package_count }}+{{
                shop.good.name
              }}， {{ $t("一键上传到你的商店，销售什么商品由你来决定") }}</span
            >
          </div>
          <div class="d-flex align-center mb-6 align-start">
            <v-card width="16" height="16" color="primary" flat></v-card>
            <span class="pl-2 text-body-1">{{
              $t(
                "数10种类付款方式，您的顾客在选择好商品之后，可以选择当地的安全支付方式为您快捷收款"
              )
            }}</span>
          </div>
          <div class="d-flex align-center mb-6 align-start">
            <v-card width="16" height="16" color="primary" flat></v-card>
            <span class="pl-2 text-body-1">{{
              $t("灵活的物流渠道选择，把货物安全的交付给您的顾客")
            }}</span>
          </div>
          <div class="d-flex align-center mb-6 align-start">
            <v-card width="16" height="16" color="primary" flat></v-card>
            <span class="pl-2 text-body-1">{{
              $t(
                "简化订单，跟踪付款和查看实时业务，因此您可以更专注于建立您的业务"
              )
            }}</span>
          </div>
          <div class="d-flex align-center align-start">
            <v-card width="16" height="16" color="primary" flat></v-card>
            <span class="pl-2 text-body-1">{{
              $t("直接在商店里与消费者沟通，提升千倍销量，接触并留住消费者")
            }}</span>
          </div>
        </v-card>
        <v-row class="ma-0 mt-10" justify="center">
          <a :href="'http://'+shop_info.shop_web_domain" target="_blank">
            <v-btn
              class="primary-background px-8 rounded-pill white--text"
              large
              height="56"
              >{{ $t("点击进入我的店铺") }}</v-btn
            >
          </a>
        </v-row>
      </div>
    </v-card>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      shop: "",
      shop_info:null,
      user_info:null
    };
  },
  mounted() {
    this.shop_info=JSON.parse(localStorage.getItem('DROPSHOPINFO')) ;
    this.user_info=JSON.parse(localStorage.getItem('DROPSHOPUSERINFO'));
    // if (
    //   localStorage.getItem("shop") != undefined &&
    //   localStorage.getItem("shop")
    // ) {
    //   this.shop = JSON.parse(localStorage.getItem("shop"));
    // }
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 1000px;
}
.margin90 {
  margin-top: 90px;
}
@media (max-width: 1000px) {
  .box {
    width: 80%;
  }
  .bg {
    max-width: 468px;
  }
  .margin90 {
    margin-top: 90px;
  }
}
@media (max-width: 500px) {
  .box {
    width: 95%;
  }
  .bg {
    width: 300px;
    text-align: center;
  }
  .margin90 {
    margin-top: 40px;
  }
}
</style>